window.onload = function () {
//$(document).ready(function () { 

  var canvas = document.getElementById('braincanvas');
  var can_w = canvas === null ? 0 : parseInt(canvas.getAttribute('width'));
  var can_h = canvas === null ? 0 : parseInt(canvas.getAttribute('height'));
  var ctx = canvas === null ? null : canvas.getContext('2d');

  var strokeOverviewCanvas = document.getElementById('stroke-page-canvas');
  var strokeOverviewCanvas_w = strokeOverviewCanvas === null ? 0 : parseInt(strokeOverviewCanvas.getAttribute('width'));
  var strokeOverviewCanvas_h = strokeOverviewCanvas === null ? 0 : parseInt(strokeOverviewCanvas.getAttribute('height'));
  var strokeOverviewCanvas_ctx = strokeOverviewCanvas === null ? null : strokeOverviewCanvas.getContext('2d');


  var ball = {
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      r: 0,
      alpha: 1,
      phase: 0
   },
   ball_color = {
       r: 255,
       g: 255,
       b: 255
   },
   R = 0.5,
   brainballs = [],
   strokeballs = [],
   alpha_f = 0.05,
   alpha_phase = 0,
    
   // Line
   link_line_width = 0.8,
   //dis_limit = 260,
   dis_limit = 190,
   add_mouse_point = false,
   mouse_in = false,
   mouse_ball = {
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      r: 0,
      type: 'mouse'
   };

  function randomArrayItem(arr){
      return arr[Math.floor(Math.random() * arr.length)];
  }
  function randomNumFrom(min, max){
      return Math.random()*(max - min) + min;
  }

  // Draw Ball
  function renderBalls(){
	
   
  }

  // Update balls
  function updateBalls(){
	
  
    
  }

// Draw lines
function renderLines(canvas_ctx, balls){
    var fraction, alpha;
    for (var i = 0; i < balls.length; i++) {
	
        if (balls[i + 2] != null && balls[i + 2].type != "mouse") {
                //console.log(balls[i+2])
			// fraction = getDisOf(balls[i], balls[i+2]) / dis_limit;
			}
	
        for (var j = i + 1; j < balls.length; j++) {
           
       
           fraction = getDisOf(balls[i], balls[j]) / dis_limit;
		  		
           if (fraction < 1.8) {
               
               alpha = (1 - fraction).toString();
				
				//if (alpha < 0.42){
				//	ctx.strokeStyle = 'rgba(10,143,205,'+alpha+')';
					
				//	ctx.lineWidth = 1;
					
				 
				//} else {
               canvas_ctx.strokeStyle = 'rgba(255,255,255,' + alpha + ')';
				
               canvas_ctx.lineWidth = 1;
				 
               //} 
					
					//    console.log(balls[i].type);
                    

               canvas_ctx.beginPath();
               if (balls[j].type == "mouse") {
                   canvas_ctx.moveTo(balls[i].x, balls[i].y);
                   canvas_ctx.lineTo(balls[j].x, balls[j].y);
                   canvas_ctx.stroke();
              }
			   
               canvas_ctx.closePath();
			   

           

			   
				
           }
        }
    }
}


// calculate distance between two points
function getDisOf(b1, b2){
    var  delta_x = Math.abs(b1.x - b2.x),
       delta_y = Math.abs(b1.y - b2.y);
    
    return Math.sqrt(delta_x*delta_x + delta_y*delta_y);
}

// Render
function render(){

    ctx.clearRect(0, 0, can_w, can_h);
    strokeOverviewCanvas_ctx.clearRect(0, 0, strokeOverviewCanvas_w, strokeOverviewCanvas_h);
   // renderBalls();
    renderLines(ctx, brainballs);
    renderLines(strokeOverviewCanvas_ctx, strokeballs);
 //   updateBalls(); // glow effect
    window.requestAnimationFrame(render);
}

// Init Balls
function initBalls(num, balls){
	    balls.push({
            x: 100,
            y: 60,
            vx: 0,
            vy: 0,
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10)
        });		
}

function drawBall(xPos, yPos, type, ballsArr){
  //  console.log(ballsArr);
var x = (1930 - window.innerWidth)/2; // 1940 was screen width when pointing out coords...
//var y = 950 - window.innerHeight;



    if (window.innerWidth < 500) {
        xPos = ((xPos - x)/1.35)+40;
        yPos = (yPos/1.35)+40;

    } else {
        xPos = xPos - x;
        yPos = yPos;//+20; // +20 because of some css top margin to SVG
    }

    ballsArr.push({
            x: xPos,
            y: yPos,
            vx: 0,
            vy: 0,
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10),
		    type: null
        });
}

// Init Canvas
function initCanvas(){
    canvas.setAttribute('width', window.innerWidth);
    can_w = parseInt(canvas.getAttribute('width'));
    can_h = parseInt(canvas.getAttribute('height'));

    strokeOverviewCanvas.setAttribute('width', window.innerWidth);
    strokeOverviewCanvas_w = parseInt(strokeOverviewCanvas.getAttribute('width'));
    strokeOverviewCanvas_h = parseInt(strokeOverviewCanvas.getAttribute('height'));
}
window.addEventListener('resize', function(e){
   // console.log('Window Resize...');
  if (canvas !== null) {
    initCanvas();
  }
});

function goMovie(){
    initCanvas();	   
	
    drawBall(693, 278+20, null, brainballs);
    drawBall(725, 233 + 20, null, brainballs);
    drawBall(796, 171 + 20, null, brainballs);
    drawBall(843, 144 + 20, null, brainballs);
    drawBall(906, 138 + 20, null, brainballs);
    drawBall(945, 122 + 20, null, brainballs);
    drawBall(997, 136 + 20, null, brainballs);
    drawBall(1045, 123 + 20, null, brainballs);
    drawBall(1077, 131 + 20, null, brainballs);
    drawBall(1118, 152 + 20, null, brainballs);
    drawBall(1128, 177 + 20, null, brainballs);
    drawBall(1163, 175 + 20, null, brainballs);
    drawBall(1186, 210 + 20, null, brainballs);
    drawBall(1203, 233 + 20, null, brainballs);
    drawBall(1233, 258 + 20, null, brainballs);
    drawBall(1235, 300 + 20, null, brainballs);
    drawBall(1267, 333 + 20, null, brainballs);
    drawBall(1263, 418 + 20, null, brainballs);
    drawBall(1223, 459 + 20, null, brainballs);
    drawBall(1208, 521 + 20, null, brainballs);
    drawBall(1150, 554 + 20, null, brainballs);
    drawBall(1063, 664 + 20, null, brainballs);
    drawBall(1070, 624 + 20, null, brainballs);
    drawBall(1072, 594 + 20, null, brainballs);
    drawBall(1092, 565 + 20, null, brainballs);
    drawBall(1040, 558 + 20, null, brainballs);
    drawBall(1013, 530 + 20, null, brainballs);
    drawBall(974, 488 + 20, null, brainballs);
    drawBall(910, 501 + 20, null, brainballs);
    drawBall(868, 488 + 20, null, brainballs);
    drawBall(831, 477 + 20, null, brainballs);
    drawBall(802, 441 + 20, null, brainballs);
    drawBall(780, 411 + 20, null, brainballs);
    drawBall(725, 400 + 20, null, brainballs);
    drawBall(694, 339 + 20, null, brainballs);
    drawBall(746, 268 + 20, null, brainballs);
    drawBall(797, 239 + 20, null, brainballs);
    drawBall(846, 246 + 20, null, brainballs);
    drawBall(798, 273 + 20, null, brainballs);
    drawBall(800, 320 + 20, null, brainballs);
    drawBall(738, 308 + 20, null, brainballs);
    drawBall(732, 345 + 20, null, brainballs);
    drawBall(801, 392 + 20, null, brainballs);
    drawBall(846, 386 + 20, null, brainballs);
    drawBall(892, 431 + 20, null, brainballs);
    drawBall(875, 262 + 20, null, brainballs);
    drawBall(883, 252 + 20, null, brainballs);
    drawBall(872, 196 + 20, null, brainballs);
    drawBall(937, 188 + 20, null, brainballs);
    drawBall(925, 261 + 20, null, brainballs);
    drawBall(997, 256 + 20, null, brainballs);
    drawBall(1023, 291 + 20, null, brainballs);
    drawBall(999, 313 + 20, null, brainballs);
    drawBall(998, 325 + 20, null, brainballs);
    drawBall(1000, 365 + 20, null, brainballs);
    drawBall(965, 383 + 20, null, brainballs);
    drawBall(943, 396 + 20, null, brainballs);
    drawBall(908, 345 + 20, null, brainballs);
    drawBall(984, 443 + 20, null, brainballs);
    drawBall(1042, 436 + 20, null, brainballs);
    drawBall(1078, 499 + 20, null, brainballs);
    drawBall(1084, 440 + 20, null, brainballs);
    drawBall(1093, 371 + 20, null, brainballs);
    drawBall(1080, 346 + 20, null, brainballs);
    drawBall(1093, 306 + 20, null, brainballs);
    drawBall(1082, 275 + 20, null, brainballs);
    drawBall(1077, 246 + 20, null, brainballs);
    drawBall(1195, 302 + 20, null, brainballs);
    drawBall(1185, 364 + 20, null, brainballs);
    drawBall(1173, 473 + 20, null, brainballs);
    drawBall(975, 820, null, brainballs);
    drawBall(1095, 785, null, brainballs);
   
    // overview strokes
    drawBall(1013, 3, null, strokeballs);
    drawBall(1040, 49, null, strokeballs);
    drawBall(800, 173, null, strokeballs);
    drawBall(880, 286, null, strokeballs);
    drawBall(805, 376, null, strokeballs);
    drawBall(978, 375, null, strokeballs);
    drawBall(1164, 375, null, strokeballs);
    drawBall(1140, 478, null, strokeballs);
    drawBall(945, 472, null, strokeballs);
    drawBall(847, 457, null, strokeballs);
    drawBall(920, 673, null, strokeballs);
    drawBall(910, 935, null, strokeballs);
    drawBall(1224, 1085, null, strokeballs);
    drawBall(1119, 1180, null, strokeballs);
    drawBall(1119, 1250, null, strokeballs);
    drawBall(1119, 1338, null, strokeballs);
    drawBall(1119, 1408, null, strokeballs);
    drawBall(1119, 1478, null, strokeballs);
    drawBall(1083, 1635, null, strokeballs);
    drawBall(737, 1791, null, strokeballs);
    drawBall(919, 1953, null, strokeballs);
    drawBall(840, 2042, null, strokeballs);
    drawBall(680, 2087, null, strokeballs);
    drawBall(642, 2213, null, strokeballs);
    drawBall(693, 2319, null, strokeballs);
    drawBall(762, 2445, null, strokeballs);
    drawBall(1190, 2464, null, strokeballs);
    drawBall(1165, 2680, null, strokeballs);
    drawBall(1263, 2900, null, strokeballs);


    window.requestAnimationFrame(render);
}


  document.addEventListener('scroll', function (event) {

    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    if (scrollTop > 100) {
      //document.querySelector('section#home svg.paralax').classList.add('on');
      $('.navbar').addClass('stick');
      //   document.querySelector('section.home svg.paralax.small').classList.add('on');
    } else {
      $('.navbar').removeClass('stick');
    }

    if (canvas !== null) {

      if ($('#about').position() !== undefined && (scrollTop + 700) > $('#about').position().top) {
        $('section#about svg.paralax').addClass('on');
        $('section#about').addClass('on');
      }

      if ($('#features').position() !== undefined && (scrollTop + 700) > $('#features').position().top) {
        $('section#features svg.paralax').addClass('on');
        $('section#features').addClass('on');
      }
      if ($('#customers').position() !== undefined && (scrollTop + 700) > $('#customers').position().top) {
        $('section#customers svg.paralax').addClass('on');
        $('section#customers').addClass('on');
      }
    //if ((scrollTop + 200) > $('#footer').position().top) {
    //    $('section#footer svg.paralax').addClass('on');
    //}
    }
  })

// helper for pointing out x and y coords
function getClickPosition(e) {
    //var xPosition = e.clientX;
    //xPosition -= strokeOverviewCanvas.offsetLeft;
    
    //var yPosition = e.clientY;
    //yPosition -= strokeOverviewCanvas.offsetTop;
    var x;
    var y;
    if (e.pageX || e.pageY) {
        x = e.pageX;
        y = e.pageY;
    }
    else {
        x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    x -= canvas.offsetLeft;
    y -= canvas.offsetTop;

console.log("drawBall(" + x + ", " + y + ");");
}

  if (canvas !== null) {
    canvas.addEventListener("click", getClickPosition, false);

    canvas.addEventListener('mouseenter', function () {
      mouse_in = true;
      brainballs.push(mouse_ball);

    });

    canvas.addEventListener('mouseleave', function () {

      mouse_in = false;
      var new_balls = [];
      Array.prototype.forEach.call(brainballs, function (b) {
        if (b.type != "mouse") {
          new_balls.push(b);
        }
      });
      brainballs = new_balls.slice(0);
    });

    canvas.addEventListener('mousemove', function (e) {
      var e = e || window.event;
      mouse_ball.x = e.pageX;
      mouse_ball.y = e.pageY;
    });
  }


  if (strokeOverviewCanvas !== null) {
    strokeOverviewCanvas.addEventListener('mouseenter', function () {
      mouse_in = true;

      strokeballs.push(mouse_ball);

    });


    strokeOverviewCanvas.addEventListener('mouseleave', function () {

      mouse_in = false;
      var new_balls = [];
      Array.prototype.forEach.call(strokeballs, function (b) {
        if (b.type != "mouse") {
          new_balls.push(b);
        }
      });
      strokeballs = new_balls.slice(0);
    });


    strokeOverviewCanvas.addEventListener('mousemove', function (e) {
      var e = e || window.event;
      //mouse_ball.x = 1075;
      //mouse_ball.y = 278;
      var x;
      var y;
      if (e.pageX || e.pageY) {
        x = e.pageX;
        y = e.pageY;
      }
      else {
        x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }
      x -= strokeOverviewCanvas.offsetLeft;
      y -= strokeOverviewCanvas.offsetTop;


      mouse_ball.x = x;
      mouse_ball.y = y;

    });
  }


$(document).ready(function () {

    // fade svg brain in after 1 sec
    $('#brain').delay(4000).queue(function (next) {
        // init canvas movie for brain effects
        if (window.innerWidth > 1024) {
            goMovie();
        }
    });

    // video stuff
    $('.js-play').click(function (e) {
        e.preventDefault();
        var vid_num = $(this).data('vid');
        var vid_src = '';
        $('#videoModal').modal({
            backdrop: 'static',
            keyboard: false
        })
        $('#videoModal').modal('show');
        $('#vimeo-iframe-container').html('<iframe src="https://player.vimeo.com/video/244654192?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=true" width="1000" height="528" frameborder="0" allowfullscreen="" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

    });

    $('.js-stop').click(function () {
        $('#vimeo-iframe-container').html('');
    });

    if (
        navigator.appVersion.toUpperCase().indexOf("MSIE") != -1 ||
        navigator.appVersion.toUpperCase().indexOf("TRIDENT") != -1 ||
        navigator.appVersion.toUpperCase().indexOf("EDGE") != -1
    ) {
        //$("html").removeClass("not4ie");
        $("html").addClass("only4ie");
    } else {
        $("html").addClass("not4ie");
    }


});
//});
};
